import {
  initialValuesDate,
  initialValuesTime,
} from "../../utils/dateFormatter/dateRegex";

export function journeyV2Model(requestData) {
  const data = requestData ? requestData.data : false;
  if (data && !requestData.hasFailed) {
    return {
      journeyV2: getJourneyV2(data),
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

function getJourneyV2(data) {
  return {
    id: data.id,
    courseID: data.courseID,
    courseName: data.courseName,
    journeyGroupID: data.journeyGroupID,
    statusID: data.statusID,
    startDate: initialValuesDate(data.startDate),
    startTime: initialValuesTime(data.startTime),
    endDate: initialValuesDate(data.endDate),
    endTime: initialValuesTime(data.endTime),
    createUser: data.createUser,
    teacherList: data.teacherList,
    userAndGroupList: data.userAndGroupList,
    courseImagePath: data.courseImagePath,
    dateFormatID: 0,
    courseSortOrderParentCourses: data.courseSortOrderParentCourses,
    courseGuidID: data.courseGuidID,
    courseCardTemplateColor: data.courseCardTemplateColor,
  };
}
